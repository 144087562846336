import React from 'react';
import PropTypes from 'prop-types';
import { useUserIPGrabber } from '@cleverrealestate/clever-helpers';
import useGlobalTOCHandler from '../utils/useGlobalTOCHandler';
import Layout from '../components/layout/Layout';
import ArticleList from '../components/organisms/ArticleList';
import { ResourceArticleSideBar } from '../components/organisms/ArticleSideBar';
import SEO from '../components/meta/SEO';

const TipsTemplate = ({ pageContext }) => {
  useUserIPGrabber();
  useGlobalTOCHandler();

  const { articles, pagination } = pageContext;

  return (
    <Layout>
      <SEO
        pathname="/resources/"
        title="The Learning Center | Home Bay - The Learning Center"
        description="Find the answers to safely take you from listing your home for sale to closing escrow."
        canonical="/resources/"
      />

      <div className="container py-12 lg:py-20 max-w-8xl">
        <h1 className="mb-6">The Learning Center</h1>

        <div className="grid gap-16 lg:gap-32 lg:grid-cols-4">
          <div className="lg:col-span-3">
            <ArticleList articles={articles} pagination={pagination} />
          </div>

          <ResourceArticleSideBar />
        </div>
      </div>
    </Layout>
  );
};

TipsTemplate.propTypes = {
  pageContext: PropTypes.any,
};

TipsTemplate.defaultProps = {
  pageContext: {},
};

export default TipsTemplate;
